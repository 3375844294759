/* 3.2 wpo-couple-section */

.wpo-couple-section {
  padding-bottom: 120px;
  padding-top: 60px;
  overflow: hidden;
  position: relative;

  @media (max-width: 991px) {
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .couple-area {
    padding-top: 80px;

    .couple-item {
      display: flex;
      align-items: center;
      padding-left: 70px;

      @media (max-width: 1199px) {
        padding-left: 0;
      }

      @media (max-width: 991px) {
        padding-bottom: 50px;
      }

      @media (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .couple-img {
        margin-bottom: 30px;
        position: relative;
        text-align: center;
        margin-right: 30px;
        mask-size: 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        mask-image: url(../../images/couple/mask-1.svg);
        -webkit-mask-image: url(../../images/couple/mask-1.svg);

        @media (max-width: 991px) {
          margin-bottom: 10px;
        }

        @media (max-width: 767px) {
          margin-bottom: 0px;
          margin-right: 0;
          max-width: 350px;
          margin: 0 auto;
        }
      }

      .couple-img-wrap {
        position: relative;
        z-index: 1;

        .c-shape {
          position: absolute;
          left: -115px;
          top: -10px;
          z-index: -1;
          width: 110%;

          @media (max-width: 1199px) {
            left: -95px;
          }

          @media (max-width: 991px) {
            left: -68px;
          }

          @media (max-width: 767px) {
            left: -76px;
          }
        }
      }

      text-align: center;

      .couple-text {
        max-width: 460px;

        h3 {
          font-weight: 400;
          font-size: 40px;
          line-height: 57px;
          text-align: center;
          margin: 0 0 0.8em;

          @media (max-width: 1199px) {
            font-size: 36px;
          }

          @media (max-width: 991px) {
            font-size: 25px;
            margin-bottom: 10px;
          }
        }

        p {
          @media (max-width: 991px) {
            font-size: 16px;
          }
        }

        ul {
          list-style: none;
          overflow: hidden;
          padding-top: 15px;
          display: flex;
          justify-content: center;

          @include media-query(991px) {
            padding-top: 10px;
            display: flex;
            justify-content: center;
          }

          > li + li {
            margin-left: 25px;
          }

          li a {
            display: block;
            text-align: center;
            color: $theme-primary-color;
            font-size: 14px;

            &:hover {
              color: $theme-primary-color;
            }
          }
        }
      }
    }

    .col {
      &:last-child {
        .couple-item {
          justify-content: flex-end;

          @media (max-width: 767px) {
            justify-content: center;
          }
        }

        .couple-img-wrap {
          order: 2;
          -webkit-order: 2;
          -moz-order: 2;

          @media (max-width: 767px) {
            order: 1;
            -webkit-order: 1;
            -moz-order: 1;
          }

          .couple-img {
            mask-image: url(../../images/couple/mask-2.svg);
            -webkit-mask-image: url(../../images/couple/mask-2.svg);
          }

          .c-shape {
            transform: scaleX(-1);
            left: auto;
            top: auto;
            right: -88px;
            bottom: -30px;

            @media (max-width: 1199px) {
              right: -65px;
              bottom: -20px;
            }

            @media (max-width: 991px) {
              right: -35px;
            }

            @media (max-width: 767px) {
              right: -55px;
            }
          }
        }

        .couple-text {
          @media (max-width: 767px) {
            order: 2;
            -webkit-order: 2;
            -moz-order: 2;
          }
        }
      }
    }
  }

  .shape-1 {
    position: absolute;
    left: 0;
    top: 10%;
    z-index: -1;
    width: 100%;

    @media (max-width: 767px) {
      display: none;
    }

    svg {
      width: 100%;
      height: 692px;

      @media (min-width: 1950px) {
        height: 1000px;
      }

      @media (max-width: 1199px) {
        height: 600px;
      }

      @media (max-width: 991px) {
        height: 360px;
      }

      path {
        &.fill-color {
          fill: #f1f3ee;
        }

        &.stroke-color {
          stroke: #5d7c78;
        }
      }
    }
  }
}

/* 3.3 wpo-story-section */

.wpo-story-section {
  background: $section-bg-color-s3;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .wpo-story-item {
    display: flex;
    align-items: center;
    padding-top: 180px;

    @media (max-width: 1199px) {
      padding-top: 100px;
    }

    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-top: 100px;
    }

    @media (max-width: 575px) {
      padding-top: 50px;
    }

    .wpo-story-img-wrap {
      position: relative;
      z-index: 1;
      flex-basis: 60%;
      text-align: center;

      @media (max-width: 1399px) {
        flex-basis: 50%;
      }

      @media (max-width: 1199px) {
        flex-basis: 45%;
      }

      @media (max-width: 991px) {
        flex-basis: 100%;
        max-width: 600px;
        margin: 0 auto;
      }

      @media (max-width: 575px) {
        max-width: 400px;
      }

      .wpo-story-img {
        padding: 20px;
        margin: 0 auto;
        background: $white;
        max-width: 340px;

        @media (max-width: 1199px) {
          max-width: 260px;
        }
      }

      .clip-shape {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;
        z-index: -1;
        display: none;

        svg {
          width: 382px;
          height: 440px;

          @media (max-width: 1199px) {
            width: 285px;
            height: 349px;
          }

          path {
            fill: $white;
          }
        }
      }

      .wpo-img-shape {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        z-index: -11;
        transform: translate(-50%, -50%);

        @media (max-width: 991px) {
          width: 105%;
          max-width: 450px;
          margin: 0 auto;
        }

        @media (max-width: 450px) {
          width: 130%;
        }
      }
    }

    .wpo-story-content {
      flex-basis: 50%;
      position: relative;
      max-width: 450px;

      @media (max-width: 991px) {
        padding-left: 0px;
        margin-left: 0;
        text-align: center;
        margin: 0 auto;
        flex-basis: 100%;
        position: relative;
        max-width: 100%;
        margin-top: 70px;
      }

      @media (max-width: 575px) {
        margin-top: 30px;
      }

      @media (max-width: 575px) {
        padding: 10px;
      }

      .wpo-story-content-inner {
        position: relative;

        h2 {
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 20px;

          @media (max-width: 1199px) {
            margin-bottom: 20px;
            font-size: 25px;
            line-height: 36px;
          }

          @media (max-width: 575px) {
            font-size: 25px;
            margin-bottom: 10px;
          }
        }

        span {
          font-style: normal;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          color: $theme-primary-color-s2;
          margin-bottom: 10px;
          display: block;

          @media (max-width: 1199px) {
            margin-bottom: 10px;
          }

          @media (max-width: 575px) {
            font-size: 18px;
          }
        }

        p {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          color: $text-color;
          margin-bottom: 0;

          @media (max-width: 1399px) {
            font-size: 18px;
          }

          @media (max-width: 575px) {
            font-size: 17px;
          }
        }
      }
    }

    &:first-child {
      padding-top: 120px;

      @media (max-width: 1199px) {
        padding-top: 80px;
      }

      @media (max-width: 991px) {
        padding-top: 50px;
      }

      @media (max-width: 575px) {
        padding-top: 20px;
      }
    }

    &:nth-child(2) {
      .wpo-story-img-wrap {
        .wpo-story-img {
          padding: 0;
          clip-path: polygon(0 24%, 50% 0, 100% 24%, 100% 76%, 49% 100%, 0 76%);
        }

        .clip-shape {
          display: block;
        }
      }
    }

    &:nth-child(even) {
      .wpo-story-img-wrap {
        order: 2;
        -webkit-order: 2;
        -moz-order: 2;

        @media (max-width: 991px) {
          order: unset;
          -webkit-order: unset;
          -moz-order: unset;
        }
      }

      .wpo-story-content {
        order: 1;
        -webkit-order: 1;
        -moz-order: 1;
        flex: 1;
        margin-left: 0;
        position: relative;
        padding-left: 0;
        flex-basis: 50%;
        margin: auto;
        margin-top: 70px;

        @media (max-width: 991px) {
          padding: 20px;
          order: unset;
          -webkit-order: unset;
          -moz-order: unset;
          flex-basis: 100%;
          margin-right: 0;
          text-align: center;
          flex-basis: 100%;
          position: relative;
          max-width: 100%;
        }

        @media (max-width: 575px) {
          padding: 10px;
          margin-top: 30px;
        }

        .wpo-story-content-inner {
          position: relative;
          text-align: right;
          max-width: 450px;

          @media (max-width: 991px) {
            max-width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .flower-shape-1 {
    position: absolute;
    left: 0;
    top: 10%;
    z-index: -1;

    @media (max-width: 575px) {
      display: none;
    }
  }

  .flower-shape-2 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;

    @media (max-width: 575px) {
      display: none;
    }
  }

  .flower-shape-3 {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);

    @media (max-width: 575px) {
      display: none;
    }
  }

  .flower-shape-4 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    animation: bounce 3s linear infinite;

    @media (max-width: 575px) {
      display: none;
    }
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }

  50% {
    -webkit-transform: translateY(15px) translateX(7);
    transform: translateY(15px) translateX(7);
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }

  50% {
    -webkit-transform: translateY(15px) translateX(7px);
    transform: translateY(15px) translateX(7px);
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}

/* 3.4 wpo-portfolio-section */

.wpo-portfolio-section,
.wpo-portfolio-section-s2 {
  position: relative;
  padding-top: 10px;
  overflow: hidden;
  padding-bottom: 60px;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30%;
    content: "";
    background: $section-bg-color-s3;
  }

  .container-fluid {
    padding: 0;
  }

  .portfolio-grids {
    .grid {
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    .slick-list {
      padding-top: 145px;

      @media (max-width: 991px) {
        padding-top: 0px;
      }
    }

    .slick-slider .slick-slide {
      &:nth-child(even) .grid {
        @media (min-width: 991px) {
          transform: translateY(0);
          animation: margin 10s linear infinite;
        }
      }

      &:nth-child(odd) .grid {
        @media (min-width: 991px) {
          transform: translateY(0);
          animation: marginOdd 10s linear infinite;
        }
      }

      @-webkit-keyframes margin {
        0% {
          transform: translateY(-145px);
        }

        50% {
          transform: translateY(-50px);
        }

        100% {
          transform: translateY(-145px);
        }
      }

      @keyframes margin {
        0% {
          transform: translateY(-145px);
        }

        50% {
          transform: translateY(-50px);
        }

        100% {
          transform: translateY(-145px);
        }
      }

      @-webkit-keyframes marginOdd {
        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(-145px);
        }

        100% {
          transform: translateY(0);
        }
      }

      @keyframes marginOdd {
        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(-145px);
        }

        100% {
          transform: translateY(0);
        }
      }
    }

    // slider controls
    .slick-prev,
    .slick-next {
      background-color: transparentize($theme-primary-color, 0.3);
      width: 45px;
      height: 45px;
      z-index: 10;
      @include rounded-border(50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      border: 2px solid $theme-primary-color;

      &:hover {
        background-color: $theme-primary-color;
      }
    }

    .slick-prev {
      left: 0px;

      @include media-query(767px) {
        display: none !important;
      }

      &:before {
        font-family: "themify";
        content: "\e629";
        opacity: 1;
      }
    }

    .slick-next {
      right: 0px;

      @include media-query(767px) {
        display: none !important;
      }

      &:before {
        font-family: "themify";
        content: "\e628";
        opacity: 1;
      }
    }

    &:hover {
      .slick-next {
        right: 20px;
        opacity: 1;
        visibility: visible;
      }

      .slick-prev {
        left: 20px;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .grid {
    padding: 15px;
    background: $white;

    .img-holder {
      position: relative;

      .hover-content {
        transition: all 0.3s;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        text-align: center;
        z-index: 11;

        i {
          font-size: 30px;
          color: $dark-gray;
        }
      }

      &:before {
        position: absolute;
        left: 2%;
        top: 2%;
        width: 96%;
        height: 96%;
        content: "";
        background: rgba(255, 255, 255, 0.8);
        opacity: 0;
        transition: all 0.3s;
        transform: scale(0);
        z-index: 1;
      }
    }
  }

  .img-holder:hover .hover-content {
    opacity: 1;
  }

  .img-holder:hover {
    &:before {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.pt-120 {
    padding-top: 120px;

    @include media-query(991px) {
      padding-top: 90px;
    }

    @include media-query(767px) {
      padding-top: 80px;
    }

    @include media-query(575px) {
      padding-top: 60px;
    }
  }
}

/* 3.5 wpo-contact-section */

.wpo-contact-section,
.wpo-contact-section-s2,
.wpo-contact-section-s3,
.wpo-contact-section-s4,
.wpo-contact-section-s5,
.wpo-contact-section-s6,
.wpo-contact-section-s7,
.wpo-contact-section-s8 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 20px;

  .contact-img {
    img {
      width: 100%;
      min-height: 600px;
      object-fit: cover;

      @media (max-width: 767px) {
        min-height: unset;
      }
    }
  }

  .wpo-contact-section-wrapper {
    position: relative;
    margin: 0 auto;
    background-color: $white;
    border: 1px solid #e0e0e0;
    z-index: 1;
    max-width: 530px;

    &:before {
      position: absolute;
      left: -4%;
      top: -20px;
      width: 108%;
      height: 198px;
      background: $theme-primary-color;
      content: "";
      z-index: -1;

      @media (max-width: 767px) {
        height: 190px;
      }

      @media (max-width: 575px) {
        height: 120px;
      }
    }

    .wpo-section-title {
      padding: 60px 0;
      background: #f8f8f7;
      position: relative;
      z-index: 1;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        padding: 30px 0;
      }

      h2 {
        font-size: 40px;
        font-style: normal;
        font-weight: 400;

        @media (max-width: 575px) {
          font-size: 30px;
        }
      }

      @media (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    .wpo-contact-form-area {
      padding: 80px 40px;
      position: relative;
      z-index: 99;
      padding-top: 0;

      @media (max-width: 1600px) {
        padding: 60px 30px;
        padding-top: 0;
      }

      @media (max-width: 1199px) {
        padding: 60px 20px;
        padding-top: 0;
      }

      @media (max-width: 575px) {
        padding: 20px 10px;
        padding-top: 0;
      }

      .form-field {
        margin-bottom: 20px;
      }

      .errorMessage {
        color: red;
        font-size: 16px;
        margin-top: 5px;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      .form-control,
      .react-datepicker__input-container input {
        width: 100%;
        height: 50px;
        border: 0;
        border-bottom: 1px solid #d9d9d9;
        color: $text-color;
        border-radius: 0;
        font-size: 18px;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }
      }

      .form-control::-webkit-input-placeholder {
        /* Edge */
        color: $body-color;
      }

      .form-control:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $body-color;
      }

      .form-control::placeholder {
        color: $body-color;
      }

      select.form-control {
        color: $body-color;
        -webkit-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: transparent url(../../images/select-icon2.png) no-repeat
          calc(100% - 15px) center;
        position: relative;

        option {
          color: $text-color;
        }
      }

      .react-datepicker__input-container input {
        -webkit-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: transparent url(../../images/date2.png) no-repeat
          calc(100% - 15px) center;
      }

      .radio-buttons {
        display: flex;
        padding: 20px 0;

        @media (max-width: 575px) {
          display: block;
        }

        p {
          margin-bottom: 0;

          & + p {
            margin-left: 30px;

            @media (max-width: 575px) {
              margin-left: 0px;
            }
          }
        }

        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
          position: absolute;
          left: -9999px;
        }

        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #666;
        }

        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 1px solid #ddd;
          border-radius: 100%;
          background: #fff;
        }

        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          content: "";
          width: 12px;
          height: 12px;
          background: $theme-primary-color;
          position: absolute;
          top: 3px;
          left: 3px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }

        [type="radio"]:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }

        [type="radio"]:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        label {
          @media (max-width: 1399px) {
            font-size: 16px;
          }
        }
      }

      .select.last {
        margin-bottom: 50px;
      }

      .submit-area {
        margin-top: 40px;
        text-align: center;
      }
    }
  }

  .bottom-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;

    @media (max-width: 1100px) {
      bottom: -30px;
    }

    @media (max-width: 870px) {
      bottom: -40px;
    }

    @media (max-width: 575px) {
      bottom: -60px;
    }

    svg {
      width: 100%;
      height: 634px;

      @media (min-width: 1950px) {
        height: 1000px;
      }

      @media (max-width: 1700px) {
        height: 555px;
      }

      @media (max-width: 1500px) {
        height: 505px;
      }

      @media (max-width: 1400px) {
        height: 465px;
      }

      @media (max-width: 1300px) {
        height: 425px;
      }

      @media (max-width: 1160px) {
        height: 380px;
      }

      @media (max-width: 870px) {
        height: 320px;
      }

      @media (max-width: 767px) {
        height: 300px;
      }

      @media (max-width: 575px) {
        height: 280px;
      }

      @media (max-width: 450px) {
        height: 200px;
      }

      .bg-path {
        fill: #a5aa9c;
      }

      .bg-stroke {
        stroke: #a5aa9c;
      }
    }

    .shape-1 {
      position: absolute;
      left: 0;
      bottom: 10px;
      animation: bounce 3s linear infinite;
    }

    .shape-2 {
      position: absolute;
      left: 68px;
      bottom: -25px;

      @media (max-width: 1600px) {
        max-width: 20%;
      }
    }

    .shape-3 {
      position: absolute;
      left: 27%;
      bottom: -25px;

      @media (max-width: 1600px) {
        max-width: 10%;
      }
    }

    .shape-4 {
      position: absolute;
      left: 65%;
      bottom: 0;
      animation: bounce 3s linear infinite;

      @media (max-width: 1600px) {
        max-width: 10%;
      }
    }

    .shape-5 {
      position: absolute;
      right: 15%;
      bottom: 0;

      @media (max-width: 1600px) {
        max-width: 10%;
      }
    }

    .shape-6 {
      position: absolute;
      right: 0;
      bottom: 0;

      @media (max-width: 1600px) {
        max-width: 10%;
      }

      @media (max-width: 991px) {
        max-width: 20%;
      }

      @media (max-width: 767px) {
        max-width: 30%;
      }
    }
  }

  &.pt-120 {
    padding-top: 120px;

    @include media-query(991px) {
      padding-top: 90px;
    }

    @include media-query(767px) {
      padding-top: 80px;
    }

    @include media-query(575px) {
      padding-top: 60px;
    }
  }
}

/* 3.6 wpo-event-section */

.wpo-event-section,
.wpo-event-section-s2,
.wpo-event-section-s4 {
  padding-bottom: 90px;

  @media (max-width: 991px) {
    padding-bottom: 80px;
  }

  @media (max-width: 767px) {
    padding-bottom: 70px;
  }

  .wpo-event-item {
    text-align: center;
    max-width: 340px;
    margin: 0 auto;
    margin-bottom: 30px;

    .wpo-event-img {
      max-width: 340px;
      margin: 0 auto;
      padding: 20px;
      border-radius: 50%;
      background: $section-bg-color-s3;
      margin-bottom: 30px;

      .wpo-event-img-inner {
        overflow: hidden;
        border-radius: 50%;

        img {
          border-radius: 50%;
          transition: all 0.3s;
          transform: scale(1);
        }
      }
    }

    &:hover {
      .wpo-event-img {
        .wpo-event-img-inner {
          img {
            -webkit-transform: scale(1.2) rotate(5deg);
            -ms-transform: scale(1.2) rotate(5deg);
            transform: scale(1.2) rotate(5deg);
          }
        }
      }
    }

    .wpo-event-text {
      h2 {
        padding: 10px 0;
        background: $theme-primary-color-s3;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $white;

        @media (max-width: 757px) {
          font-size: 25px;
        }
      }

      ul {
        list-style: none;
        margin: 0 auto;
        margin-top: 30px;

        li {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150.2%;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          button {
            color: $theme-primary-color;
            position: relative;
            display: inline-block;
            margin-top: 10px;
            font-size: 18px;
            text-transform: capitalize;

            &:hover,
            &:focus {
              background: none;
              outline: none;
              box-shadow: none;
            }

            &::before {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              content: "";
              background: $theme-primary-color;
            }
          }
        }
      }
    }
  }
}

.MuiDialog-paperWidthSm {
  max-width: 900px !important;
}

.quickview-dialog {
  z-index: 999999 !important;
}

.modal-body {
  iframe {
    width: 800px;
    height: 500px;

    @media (max-width: 991px) {
      width: 500px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.MuiDialogTitle-root {
  padding-bottom: 0 !important;
}

.MuiPaper-root .event-close-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background: $white;
  border-radius: 50%;
  z-index: 11;
}

/* 3.7 wpo-blog-section */

.wpo-blog-section,
.wpo-blog-section-s2,
.wpo-blog-section-s3 {
  padding-bottom: 90px;
  background: $section-bg-color-s3;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 767px) {
    padding-bottom: 60px;
  }

  .b-shape {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;

    @media (max-width: 1440px) {
      max-width: 60%;
    }

    @media (max-width: 575px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .wpo-blog-items {
    position: relative;
    z-index: 1;

    .b-shape-2 {
      position: absolute;
      left: -190px;
      top: -120px;
      z-index: -1;
    }
  }

  .wpo-blog-item {
    margin-bottom: 30px;

    .wpo-blog-img {
      overflow: hidden;

      img {
        width: 100%;
        -webkit-filter: grayscale(0);
        -moz-filter: grayscale(0);
        -o-filter: grayscale(0);
        -ms-filter: grayscale(0);
        filter: grayscale(0);
        transition: all 0.3s;
        transform: scale(1);
      }
    }

    &:hover {
      .wpo-blog-img {
        img {
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          filter: grayscale(100%);
          transform: scale(1.2);
        }
      }
    }

    .wpo-blog-content {
      background: $white;
      padding: 30px 20px;

      @media (max-width: 1399px) {
        padding: 20px;
      }

      ul {
        list-style: none;
        display: flex;
        margin-bottom: 15px;

        li {
          color: $text-color;

          @media (max-width: 1199px) {
            font-size: 16px;
          }

          &:first-child {
            padding-right: 30px;
            position: relative;

            @media (max-width: 1199px) {
              padding-right: 22px;
            }

            &:before {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              width: 6px;
              height: 6px;
              content: "";
              background: $text-color;
              border-radius: 50%;
            }
          }

          a {
            color: $text-color;

            &:hover {
              color: $theme-primary-color;
            }
          }
        }
      }

      h2 {
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
        padding-right: 20px;
        margin-bottom: 0;

        @media (max-width: 1399px) {
          font-size: 26px;
        }

        @media (max-width: 1199px) {
          font-size: 23px;
        }

        a {
          color: $heading-color;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
    }
  }

  &.bg-white {
    .wpo-blog-item {
      margin-bottom: 30px;

      .wpo-blog-img {
        overflow: hidden;

        img {
          width: 100%;
          -webkit-filter: grayscale(0);
          -moz-filter: grayscale(0);
          -o-filter: grayscale(0);
          -ms-filter: grayscale(0);
          filter: grayscale(0);
          transition: all 0.3s;
          transform: scale(1);
        }
      }

      &:hover {
        .wpo-blog-img {
          img {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            filter: grayscale(100%);
            transform: scale(1.2);
          }
        }
      }

      .wpo-blog-content {
        background: #fff;
        box-shadow: 0px 2px 10px 1px rgba(1, 0, 57, 0.1);
      }
    }
  }
}
